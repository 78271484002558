import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { values } from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DropdownCheckbox = (props) => {
    const data = values(props.data);
    return (
        <Autocomplete
            onChange={(e, n) => props.dataSelectHandler(e, n)}
            // multiple
            id="checkboxes-tags"
            options={data}
            // disableCloseOnSelect
            getOptionLabel={(option) => {
                return option.name + ' (' + option.n + ')';
            }}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    {/* <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    /> */}
                    {option.name} ({option.n})
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={props.label} />
            )}
        />
    );
}




export default DropdownCheckbox;
