import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    borderRadius: 5,
    transition: "0.4s",
    backgroundColor: "rgba(0,0,0,0.03)",
    boxShadow: "22px 12px 40px -12px rgba(0,0,0,0.10)",
    "&:hover": {
      cursor: "pointer",
    },
  },
  content: {
    flex: "1 1 auto",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    width: "100%",
    margin: theme.spacing(2),
  },
}));

const ProductCard = (props) => {
  const classes = useStyles();
  const { product } = props;
  return (
    <Link
      to={{ pathname: "/products/details", search: `id=${product.id}` }}
      className={classes.link}
    >
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography variant="h6" gutterBottom>
            <strong>{product.name.toUpperCase()}</strong>
          </Typography>
          {product.mapped_offers !== 0 && (
            <Typography gutterBottom>
              <strong>{product.mapped_offers}</strong> Offer(s)
            </Typography>
          )}
          {product.year && (
            <Typography gutterBottom>
              Year <strong>{product.year}</strong>
            </Typography>
          )}
          {product.status && (
            <Typography>
              In <strong>{product.status} Condition</strong>
            </Typography>
          )}
        </CardContent>
      </Card>
    </Link>
  );
};

export default ProductCard;
