import instance from "../axiosInstance";
// export const URL = `https://1ade502d2d77.ngrok.io/api/rv-logs/`;
// export const URLCOUNT = `https://1ade502d2d77.ngrok.io/api/rv-logs-count/`;
// export const URLLOGIN = `https://1ade502d2d77.ngrok.io/api/token/`;
// export const URLREFRESH = `https://1ade502d2d77.ngrok.io/api/token/refresh/`;

// 167.99.107.137sa
// export const URL = `http://167.99.107.137/api/rv-logs/`;
// export const URLCOUNT = `http://167.99.107.137/api/rv-logs-count/`;
// export const URLLOGIN = `http://167.99.107.137/api/token/`;

export const URL = instance.defaults.baseURL + `/users/users/`;
export const URLCOUNT = `https://api.toolkitrvp.com/api/rv-logs-count/`;
export const URLREFRESH = `https://api.toolkitrvp.com/api/token/refresh/`;
export const URLLOGIN = `/login/`;
