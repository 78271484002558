import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "@material-ui/lab/Pagination";
import { requestProducts, setProductsViewFilters } from "../../../actions";
import { getProductsViewFiltersData } from "../../../selectors/selector";
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import DropdownCheckbox from "./dropdowncheckbox";
const filterMethod = [
  {
    value: "nofilter",
    label: "No Filter",
  },
  {
    value: "mapped",
    label: "Mapped Products",
  },
  {
    value: "unmapped",
    label: "Unmapped Products",
  },
];

const sortMethod = [
  {
    value: "default",
    label: "Default Sorting",
  },
  {
    value: "name",
    label: "Sort By Title",
  },
  {
    value: "offers",
    label: "Sort By Offers",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20vw",

    },
  },
  rootpage: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  pagination: {
    // marginTop: 10,
    marginBottom: 20,
  },
  main: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  box: {
    display: "flex",
    width: "23vw",
  },
  push: {
    marginLeft: "auto",
  },
  full: {
    width: "100%",
    // width: "23vw",
  }
}));

const Filters = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const viewFilters = useSelector(getProductsViewFiltersData);

  const [filter, setFilter] = useState(viewFilters.filter);
  const [title, setTitle] = useState(viewFilters.title);
  const [sort, setSort] = useState(viewFilters.sort);
  const [currentPage, setCurrentPage] = useState(viewFilters.page);
  const [brand, setBrand] = useState(viewFilters.brand);
  const [hasBrand, setHasBrand] = useState(viewFilters.hasBrand);
  const [model, setModel] = useState(viewFilters.model);
  const [hasModel, setHasModel] = useState(viewFilters.hasModel);
  const [variant, setVariant] = useState(viewFilters.viariant);
  const [hasVariant, setHasVariant] = useState(viewFilters.hasVariant);

  const filterChangeHandler = (event) => {
    setFilter(event.target.value);
  };

  const sortChangeHanlder = (event) => {
    setCurrentPage(1);
    setSort(event.target.value);
  };

  const paginate = useCallback((event, value) => {
    setCurrentPage(value);
  }, []);

  const titleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      filter: filter,
      title: title,
      sort: sort,
      brand: brand || '',
      hasBrand: hasBrand,
      model: model || '',
      hasModel: hasModel,
      variant: variant || '',
      hasVariant: hasVariant,
    };
    dispatch(requestProducts(params));
    dispatch(setProductsViewFilters(params));
    //eslint-disable-next-line
  }, [currentPage, filter, sort]);

  const brandSelectHandler = (e, n) => {
    const id = n?.brand__id || '';
    setBrand(id);
    setCurrentPage(1);
    const params = {
      page: currentPage,
      filter: filter,
      title: title,
      sort: sort,
      brand: id,
      hasBrand: hasBrand,
      model: model || '',
      hasModel: hasModel,
      variant: variant || '',
      hasVariant: hasVariant,
    };
    dispatch(requestProducts(params));
    dispatch(setProductsViewFilters(params));
  };

  const modelSelectHandler = (e, n) => {
    const id = n?.model__id || '';
    setModel(id);
    setCurrentPage(1);
    const params = {
      page: currentPage,
      filter: filter,
      title: title,
      sort: sort,
      brand: brand || '',
      hasBrand: hasBrand,
      model: id,
      hasModel: hasModel,
      variant: variant || '',
      hasVariant: hasVariant,
    };
    dispatch(requestProducts(params));
    dispatch(setProductsViewFilters(params));
  };

  const variantSelectHandler = (e, n) => {
    const id = n?.variant__id || '';
    setVariant(id);
    setCurrentPage(1);
    const params = {
      page: currentPage,
      filter: filter,
      title: title,
      sort: sort,
      brand: brand || '',
      hasBrand: hasBrand,
      model: model || '',
      hasModel: hasModel,
      variant: id,
      hasVariant: hasVariant,
    };
    dispatch(requestProducts(params));
    dispatch(setProductsViewFilters(params));
  };

  const titleSubmitHandler = () => {
    setCurrentPage(1);
    const params = {
      page: currentPage,
      filter: filter,
      title: title,
      sort: sort,
      brand: brand || '',
      hasBrand: hasBrand,
      model: model || '',
      hasModel: hasModel,
      variant: variant || '',
      hasVariant: hasVariant,
    };
    dispatch(requestProducts(params));
    dispatch(setProductsViewFilters(params));
  };

  return (
    <>
      <div className={classes.main}>
        <div className={classes.pagination}>
          <Pagination
            className={classes.rootpage}
            count={props.pagesCount}
            color="primary"
            page={currentPage}
            onChange={paginate}
          />
        </div>
        <Typography>{props.productsCount} Product(s) in {props.duration} seconds.</Typography>

        <div className={classes.root} noValidate autoComplete="off">
          <TextField
            className={classes.full}
            id="sort"
            select
            label="Sort"
            value={sort}
            color="secondary"
            variant="outlined"
            onChange={sortChangeHanlder}
          >
            {sortMethod.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.full}
            label="Title"
            color="secondary"
            variant="outlined"
            value={title}
            onKeyPress={(event) => {
              if (event.charCode === 13) titleSubmitHandler();
            }}
            onChange={titleChangeHandler}
          ></TextField>

          <div className={classes.box}>
            <DropdownCheckbox label="Brands" dataSelectHandler={brandSelectHandler} data={props.brandsList} />
            <Tooltip title="No Brand" arrow className={classes.push}>
              <Checkbox
                color="primary"
                onChange={(e) => {
                  setHasBrand(e.target.checked);
                  setCurrentPage(1);
                  const params = {
                    page: currentPage,
                    filter: filter,
                    title: title,
                    sort: sort,
                    brand: brand || '',
                    hasBrand: e.target.checked,
                    model: model || '',
                    hasModel: hasModel,
                    variant: variant || '',
                    hasVariant: hasVariant,
                  };
                  dispatch(requestProducts(params));
                  dispatch(setProductsViewFilters(params));
                }}
              />
            </Tooltip>
          </div>
          <div className={classes.box}>
            <DropdownCheckbox label="Models" dataSelectHandler={modelSelectHandler} data={props.modelsList} />
            <Tooltip title="No Model" arrow className={classes.push}>
              <Checkbox
                color="primary"
                onChange={(e) => {
                  setHasModel(e.target.checked);
                  setCurrentPage(1);
                  const params = {
                    page: currentPage,
                    filter: filter,
                    title: title,
                    sort: sort,
                    brand: brand || '',
                    hasBrand: hasBrand,
                    model: model || '',
                    hasModel: e.target.checked,
                    variant: variant || '',
                    hasVariant: hasVariant,
                  };
                  dispatch(requestProducts(params));
                  dispatch(setProductsViewFilters(params));
                }}
              />
            </Tooltip>
          </div>
          <div className={classes.box}>
            <DropdownCheckbox label="Variants" dataSelectHandler={variantSelectHandler} data={props.variantsList} />
            <Tooltip title="No Variant" arrow className={classes.push}>
              <Checkbox
                color="primary"
                onChange={(e) => {
                  setHasVariant(e.target.checked);
                  setCurrentPage(1);
                  const params = {
                    page: currentPage,
                    filter: filter,
                    title: title,
                    sort: sort,
                    brand: brand || '',
                    hasBrand: hasBrand,
                    model: model || '',
                    hasModel: hasModel,
                    variant: variant || '',
                    hasVariant: e.target.checked,
                  };
                  dispatch(requestProducts(params));
                  dispatch(setProductsViewFilters(params));
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;
