import { PRODUCTSDATA, PRODUCT_INFO } from "../constants/index";

const productsState = {
  loading: false,
  error: null,
  mapLoading: false,
  mapError: null,
  mapSuccess: false,
  deleteError: null,
  deleteLoading: false,
  deleteSuccess: false,
  products: [],
  product: null,
  viewFilters: {
    page: 1,
    filter: "nofilter",
    title: "",
    sort: "default",
    brand: '',
    hasBrand: false,
    model: '',
    hasModel: false,
    variant: '',
    hasVariant: false,
  },
  brands: { result: [] },
  models: { result: [] },
  variants: { result: [] },
};

export const ProductsReducer = (state = productsState, action) => {
  switch (action.type) {
    case PRODUCTSDATA.REQUEST_PRODUCTS:
    case PRODUCTSDATA.REQUEST_PRODUCT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PRODUCTSDATA.REQUEST_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loading: false,
        error: null,
      };
    case PRODUCTSDATA.REQUEST_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload,
        loading: false,
        error: null,
      };
    case PRODUCTSDATA.REQUEST_PRODUCTS_FAILED:
    case PRODUCTSDATA.REQUEST_PRODUCT_FAILED:
      return {
        ...state,
        products: [],
        product: null,
        loading: false,
        error: action.error,
      };
    case PRODUCTSDATA.RESET_PRODUCT:
      return {
        ...state,
        product: null,
        mapLoading: false,
        mapError: null,
        deleteSuccess: false,
      };
    case PRODUCTSDATA.RESET_PRODUCTS:
      return {
        ...state,
        products: [],
      };
    case PRODUCTSDATA.SET_PRODUCTS_VIEW_FILTERS:
      return {
        ...state,
        viewFilters: action.payload,
      };
    case PRODUCTSDATA.MAP_OFFERS:
      return {
        ...state,
        mapError: null,
        mapLoading: true,
      };
    case PRODUCTSDATA.MAP_OFFERS_SUCCESS:
      return {
        ...state,
        product: action.payload,
        mapLoading: false,
        mapError: null,
      };
    case PRODUCTSDATA.MAP_OFFERS_FAILED:
      return {
        ...state,
        mapLoading: false,
        mapError: action.error,
      };
    case PRODUCTSDATA.DELETE_PRODUCT:
      return {
        ...state,
        deleteLoading: true,
      };
    case PRODUCTSDATA.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: null,
        deleteSuccess: true,
      };
    case PRODUCTSDATA.DELETE_PRODUCT_FAILED:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error,
      };
    case PRODUCT_INFO.BRANDS_LIST:
    case PRODUCT_INFO.VARIANT_LIST_FAILED:
    case PRODUCT_INFO.VARIANT_LIST:
    case PRODUCT_INFO.BRANDS_LIST_FAILED:
    case PRODUCT_INFO.MODEL_LIST:
    case PRODUCT_INFO.MODEL_LIST_FAILED:
      return {
        ...state,
      };
    case PRODUCT_INFO.VARIANT_LIST_SUCCESS:
      return { ...state, variants: action.payload };
    case PRODUCT_INFO.BRANDS_LIST_SUCCESS:
      return { ...state, brands: action.payload };
    case PRODUCT_INFO.MODEL_LIST_SUCCESS:
      return { ...state, models: action.payload };
    case PRODUCTSDATA.UPDATE_PRODUCT:
      return { ...state };
    case PRODUCTSDATA.UPDATE_PRODUCT_SUCCESS:
      return { ...state, product: action.payload };
    case PRODUCTSDATA.UPDATE_PRODUCT_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
