export const TITLE = "title";
export const NAME = "name";
export const NEGATION = "_not";
export const AND = "and";
export const OR = "or";
export const BEGINS_WITH = "beginsWith";
export const ENDS_WITH = "endsWith";
export const EQUALS = "equals";
export const LESS_THAN = "lessThan";
export const GREATER_THAN = "greaterThan";
export const LESS_THAN_EQUAL = "lessThanEqual";
export const GREATER_THAN_EQUAL = "greaterThanEqual";
export const CONTAINS = "contains";
export const MATCHES = "matches";
