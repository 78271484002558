import React, { useEffect } from "react";
import Appbar from "../../appbar";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Spinner from "../../../UI/spinner";
import List from "./list";
import Filters from "./filters";
import { resetOffers } from "../../../actions";
import { makeStyles } from "@material-ui/core";
import {
  getOffersData,
  getOffersError,
  getOffersLoading,
} from "../../../selectors/selector";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: 10,
  },
  errorMessage: {
    display: "flex",
    justifyContent: "center",
    letterSpacing: ".1rem",
    textTransform: "uppercase",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Offers = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(getOffersLoading);
  const error = useSelector(getOffersError);
  const offers = useSelector(getOffersData);
  const localStorageToken = localStorage.getItem("token");
  useEffect(() => {
    if (localStorageToken === null) history.push("/");
  }, [localStorageToken]);

  useEffect(() => {
    return () => {
      dispatch(resetOffers());
    };
  }, [resetOffers]);

  let fetchedOffers = null;
  if (error) fetchedOffers = <h2 className={classes.errorMessage}>{error}</h2>;
  if (loading)
    fetchedOffers = (
      <Backdrop className={classes.backdrop} open={loading}>
        <Spinner />
      </Backdrop>
    );
  if (!error && !loading)
    fetchedOffers = <h2 className={classes.errorMessage}>No RV Offer Found</h2>;

  if (offers.results !== undefined && offers.results.length !== 0 && !loading)
    fetchedOffers = <List offers={offers.results} />;

  return (
    <>
      <Appbar />
      <Grid
        className={classes.main}
        container
        direction="row"
        justify="space-around"
      >
        <Grid item xs={2}>
          <Filters pagesCount={offers.total_pages} />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={9}>
          {fetchedOffers}
        </Grid>
      </Grid>
    </>
  );
};

export default Offers;
