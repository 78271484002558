import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { number, string } from "yup";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    borderRadius: 5,
    transition: "0.4s",
    backgroundColor: "rgba(0,0,0,0.03)",
    boxShadow: "22px 12px 40px -12px rgba(0,0,0,0.10)",
    "&:hover": {
      cursor: "pointer",
    },
  },
  content: {
    flex: "1 1 auto",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    width: "100%",
    margin: theme.spacing(2),
  },
  dot: {
    marginLeft: "15px",
    height: "15px",
    width: "15px",
    backgroundColor: "green",
    borderRadius: "50%",
    display: "inline-block",
  },
  blocked: {
    marginLeft: "15px",
    height: "15px",
    width: "15px",
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
  },
}));

const OfferCard = (props) => {
  const classes = useStyles();
  const { offer } = props;
  const condition = offer?.condition;
  let cond = 0;
  if (typeof condition === "number") {
    if (condition === 1) {
      cond = "NEW";
    } else if (condition === 2) {
      cond = "USED";
    }
  } else if (typeof condition === "string") {
    cond = condition.toUpperCase();
  }
  const offerPrice = offer?.price;
  let price;
  if (typeof offerPrice === "string") {
    if (offerPrice === "99999999.00") {
      price = "0";
    }
    else {
      price = offerPrice;
    }
  }
  return (
    <Link
      to={{ pathname: "/offers/details", search: `id=${offer.id}` }}
      className={classes.link}
    >
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography variant="h6" gutterBottom>
            <strong>
              {offer.title.toUpperCase()} | {offer.year} | {cond}
            </strong>
            {offer.product_id && <span className={classes.dot} />}
            {offer.blocked && <span className={classes.blocked} />}
          </Typography>
          {offer.price && (
            <Typography gutterBottom>
              <strong>$ {price}</strong>
            </Typography>
          )}
          <Typography>{offer.seller_name}</Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default OfferCard;
