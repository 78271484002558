import React, { useEffect, useState } from "react";
import Appbar from "../../../appbar";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import Spinner from "../../../../UI/spinner";
import PrimaryDetails from "./primaryDetails";
import TextField from "@material-ui/core/TextField";
import ProductList from "../../Products/list";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  requestOffer,
  resetOffer,
  resetProducts,
  requestMappedProduct,
  createProduct,
  updateOffer,
  resetMappedProduct,
  requestProducts,
} from "../../../../actions";
import {
  getOfferData,
  getOffersError,
  getOffersLoading,
  getMappedProductData,
  getCreateProductLoading,
  getCreateProductError,
  getUpdateOfferError,
  getUpdateOfferLoading,
  getProductsData,
  getProductsLoading,
  getProductsError,
} from "../../../../selectors/selector";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: 20,
  },
  divider: {
    marginTop: 10,
  },
  errorMessage: {
    display: "flex",
    justifyContent: "center",
    letterSpacing: ".1rem",
    textTransform: "uppercase",
  },
  searchForm: {
    marginTop: 50,
    "& .MuiTextField-root": {
      marginTop: theme.spacing(4),
      margin: theme.spacing(1),
    },
  },
}));

const OfferDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(getOffersLoading);
  const error = useSelector(getOffersError);
  const createLoading = useSelector(getCreateProductLoading);
  const createError = useSelector(getCreateProductError);
  const updateLoading = useSelector(getUpdateOfferLoading);
  const updateError = useSelector(getUpdateOfferError);
  const offer = useSelector(getOfferData);
  const productsLoading = useSelector(getProductsLoading);
  const productsError = useSelector(getProductsError);
  const products = useSelector(getProductsData);
  const mappedProduct = useSelector(getMappedProductData);

  const [title, setTitle] = useState("");

  const titleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const localStorageToken = localStorage.getItem("token");
  useEffect(() => {
    if (localStorageToken === null) history.push("/");
  }, [localStorageToken]);

  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  useEffect(() => {
    dispatch(requestOffer(id));
  }, []);

  useEffect(() => {
    if (offer) {
      const params = {
        title: offer.result.title,
        no_pagination: true,
      };
      setTitle(offer.result.title);
      dispatch(requestProducts(params));
      if (offer.result.product_id !== null)
        dispatch(requestMappedProduct(offer.result.product_id));
      else if (mappedProduct) {
        dispatch(resetMappedProduct());
      }
    }
    // eslint-disable-next-line
  }, [offer]);

  useEffect(() => {
    return () => {
      dispatch(resetProducts());
      dispatch(resetMappedProduct());
      dispatch(resetOffer());
    };
  }, [resetProducts, resetOffer, resetMappedProduct]);

  const handleUpdateOffer = () => {
    let blocked = true;
    if (offer && offer.result.blocked) blocked = false;
    const data = {
      blocked: blocked,
    };

    dispatch(updateOffer(offer.result.id, data));
  };

  const handleCreateProduct = () => {
    dispatch(createProduct(offer.result));
    dispatch(requestProducts(params));
  };

  const handleSearchProducts = (event) => {
    event.preventDefault();
    const params = {
      title: title,
      no_pagination: true,
    };
    dispatch(requestProducts(params));
  };

  let fetchedProducts = null;
  if (productsError)
    fetchedProducts = <h2 className={classes.errorMessage}>{error}</h2>;
  if (productsLoading) fetchedProducts = <Spinner />;
  if (!productsError && !productsLoading)
    fetchedProducts = <h2 className={classes.errorMessage}>No RV Found</h2>;

  if (
    products.results !== undefined &&
    products.results.length !== 0 &&
    !productsLoading
  )
    fetchedProducts = <ProductList products={products.results} />;

  let pageContent = null;
  if (error) pageContent = <h2 className={classes.errorMessage}>{error}</h2>;
  if (loading)
    pageContent = (
      <Backdrop className={classes.backdrop} open={loading}>
        <Spinner />
      </Backdrop>
    );
  let fetchedMappedProduct = null;
  if (offer !== null && loading) {
    fetchedMappedProduct = <Spinner />;
  }
  if (mappedProduct && mappedProduct.result.length !== 0) {
    fetchedMappedProduct = <ProductList products={[mappedProduct.result]} />;
  }
  if (offer && offer.result.length !== 0)
    pageContent = (
      <Grid
        className={classes.main}
        container
        direction="row"
        justify="space-around"
      >
        <Grid item xs={3}>
          <PrimaryDetails
            offer={offer.result}
            disableCreateProduct={
              (mappedProduct && mappedProduct.result.length !== 0) ||
              offer.result.blocked
            }
            createProduct={handleCreateProduct}
            createError={createError}
            createLoading={createLoading}
            updateOffer={handleUpdateOffer}
            updateError={updateError}
            updateLoading={updateLoading}
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={8}>
          {!loading && (
            <>
              <Typography variant="h5" gutterBottom>
                {mappedProduct && mappedProduct.result.length !== 0
                  ? "MAPPED PRODUCT"
                  : "OFFER IS NOT MAPPED"}
              </Typography>
              <div className={classes.divider}>
                <Divider />
              </div>
            </>
          )}
          {fetchedMappedProduct}
          <Grid container alignItems="center" direction="column">
            <form
              onSubmit={handleSearchProducts}
              className={classes.searchForm}
              noValidate
              autoComplete="off"
            >
              <Typography variant="h5" gutterBottom>
                SEARCH PRODUCTS
              </Typography>
              <Divider />
              <Grid item>
                <TextField
                  id="title"
                  variant="outlined"
                  label="Title"
                  value={title}
                  onChange={titleChangeHandler}
                />
              </Grid>
            </form>
            {fetchedProducts}
          </Grid>
        </Grid>
      </Grid>
    );

  return (
    <>
      <Appbar />
      {pageContent}
    </>
  );
};

export default OfferDetail;
