import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Spinner from "../../../../UI/spinner";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import { Formik, Form } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divider: {
    padding: "10px 0px",
  },
  deleteError: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    textTransform: "uppercase",
    color: "red",
  },
}));

const PrimaryDetails = (props) => {
  const classes = useStyles();
  const {
    product,
    deleteProduct,
    deleteError,
    deleteLoading,
    models,
    brands,
    variants,
    handleUpdateProduct,
  } = props;

  return (
    <div className={classes.main}>
      <Typography variant="h5" gutterBottom>
        {product.title.toUpperCase()}
      </Typography>
      {deleteLoading ? (
        <Spinner />
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={deleteProduct}
          startIcon={<DeleteIcon />}
        >
          DELETE PRODUCT
        </Button>
      )}
      {deleteError && (
        <div className={classes.deleteError}>
          <Typography variant="body1">{deleteError}</Typography>
        </div>
      )}
      <div className={classes.divider}>
        <Divider />
      </div>

      <Typography variant="body1" gutterBottom>
        YEAR: <strong>{product.year ? product.year : "NULL"}</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Condition: <strong>{product.status ? product.status : "NULL"}</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Make:{" "}
        <strong>
          {product.brand ? product.brand.name.toUpperCase() : "NULL"}
        </strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Model:{" "}
        <strong>
          {product.model ? product.model.name.toUpperCase() : "NULL"}
        </strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Variant:{" "}
        <strong>
          {product.variant ? product.variant.name.toUpperCase() : "NULL"}
        </strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Offer(s): <strong>{product.mapped_offers}</strong>
      </Typography>
      <Formik initialValues={{ brand_id: "", model_id: "", variant_id: "" }}>
        {({ values, setFieldValue }) => (
          <Form>
            <Box my={2}>
              <Autocomplete
                value={values.brand_id}
                onChange={(event, newValue) => {
                  setFieldValue("brand_id", newValue);
                  handleUpdateProduct({
                    product: product.id,
                    payload: {
                      brand_id: newValue?.id,
                    },
                  });
                }}
                noOptionsText="No Items Found!"
                getOptionLabel={(option) => {
                  return option?.name || "";
                }}
                id="controllable-states-demo"
                options={brands?.result || []}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Brand"
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box my={2}>
              <Autocomplete
                value={values.model_id}
                onChange={(event, newValue) => {
                  setFieldValue("model_id", newValue);
                  handleUpdateProduct({
                    product: product.id,
                    payload: {
                      model_id: newValue?.id,
                    },
                  });
                }}
                noOptionsText="No Items Found!"
                getOptionLabel={(option) => {
                  return option?.name || "";
                }}
                id="controllable-states-demo"
                options={models?.result}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Model"
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box my={2}>
              <Autocomplete
                value={values.variant_id}
                onChange={(event, newValue) => {
                  setFieldValue("variant_id", newValue);
                  handleUpdateProduct({
                    product: product.id,
                    payload: {
                      variant_id: newValue?.id,
                    },
                  });
                }}
                noOptionsText="No Items Found!"
                getOptionLabel={(option) => {
                  return option?.name || "";
                }}
                id="controllable-states-demo"
                options={variants?.result}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Variant"
                    variant="outlined"
                  />
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PrimaryDetails;
