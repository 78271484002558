export const SCRAPPERDATA = {
  LOAD: "SCRAPPER_DATA_LOAD",
  LOAD_DATA: "LOAD_DATA",
  LOAD_SUCCESS: "SCRAPPER_DATA_LOAD_SUCCESS",
  LOAD_FAIL: "SCRAPPER_DATA_LOAD_FAIL",
  CLEAR: "SCRAPPER_DATA_CLEAR",
  ADD: "SCRAPPER_DATA_ADD",
};
export const LOGINDATA = {
  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_DATA: "LOGIN_DATA",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
};
export const ADDUSERDATA = {
  ADDUSER_LOADING: "ADDUSER_LOADING",
  ADDUSER_DATA: "ADDUSER_DATA",
  ADDUSER_SUCCESS: "ADDUSER_SUCCESS",
  ADDUSER_FAIL: "ADDUSER_FAIL",
  ADDUSER_INIT: "ADDUSER_INIT",
};
export const PRODUCTSDATA = {
  DELETE_PRODUCT: "DELETE_PRODUCT",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILED: "DELETE_PRODUCT_FAILED",
  MAP_OFFERS: "MAP_OFFERS",
  MAP_OFFERS_SUCCESS: "MAP_OFFERS_SUCCESS",
  MAP_OFFERS_FAILED: "MAP_OFFERS_FAILED",
  SET_PRODUCTS_VIEW_FILTERS: "SET_PRODUCTS_VIEW_FILTERS",
  REQUEST_PRODUCT: "REQUEST_PRODUCT",
  REQUEST_PRODUCT_SUCCESS: "REQUEST_PRODUCT_SUCCESS",
  REQUEST_PRODUCT_FAILED: "REQUEST_PRODUCT_FAILED",
  REQUEST_PRODUCTS: "REQUEST_PRODUCTS",
  REQUEST_PRODUCTS_SUCCESS: "REQUEST_PRODUCTS_SUCCESS",
  REQUEST_PRODUCTS_FAILED: "REQUEST_PRODUCTS_FAILED",
  RESET_PRODUCT: "RESET_PRODUCT",
  RESET_PRODUCTS: "RESET_PRODUCTS",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILED: "UPDATE_PRODUCT_FAILED",
};
export const OFFERSDATA = {
  UPDATE_OFFER: "UPDATE_OFFER",
  UPDATE_OFFER_SUCCESS: "UPDATE_OFFER_SUCCESS",
  UPDATE_OFFER_FAILED: "UPDATE_OFFER_FAILED",
  SET_OFFERS_VIEW_FILTERS: "SET_OFFERS_VIEW_FILTERS",
  CREATE_PRODUCT: "CREATE_PRODUCT",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_FAILED: "CREATE_PRODUCT_FAILED",
  SEARCH_OFFERS: "SEARCH_OFFERS",
  RESET_MAPPED_PRODUCT: "RESET_MAPPED_PRODUCT",
  REQUEST_MAPPED_PRODUCT: "REQUEST_MAPPED_PRODUCT",
  REQUEST_MAPPED_PRODUCT_SUCCESS: "REQUEST_MAPPED_PRODUCT_SUCCESS",
  REQUEST_MAPPED_PRODUCT_FAILED: "REQUEST_MAPPED_PRODUCT_FAILED",
  REQUEST_OFFER: "REQUEST_OFFER",
  REQUEST_OFFER_SUCCESS: "REQUEST_OFFER_SUCCESS",
  REQUEST_OFFER_FAILED: "REQUEST_OFFER_FAILED",
  REQUEST_OFFERS: "REQUEST_OFFERS",
  REQUEST_OFFERS_SUCCESS: "REQUEST_OFFERS_SUCCESS",
  REQUEST_OFFERS_FAILED: "REQUEST_OFFERS_FAILED",
  RESET_OFFER: "RESET_OFFER",
  RESET_OFFERS: "RESET_OFFERS",
};

export const PRODUCT_INFO = {
  BRANDS_LIST: "BRANDS_LIST",
  BRANDS_LIST_SUCCESS: "BRANDS_LIST_SUCCESS",
  BRANDS_LIST_FAILED: "BRANDS_LIST_FAILED",
  MODEL_LIST: "MODEL_LIST",
  MODEL_LIST_SUCCESS: "MODEL_LIST_SUCCESS",
  MODEL_LIST_FAILED: "MODEL_LIST_FAILED",
  VARIANT_LIST: "VARIANT_LIST",
  VARIANT_LIST_SUCCESS: "VARIANT_LIST_SUCCESS",
  VARIANT_LIST_FAILED: "VARIANT_LIST_FAILED",
};

export const ALL_SELLER = {
  GET_SELLERS_FETCH: "GET_SELLERS_FETCH",
  GET_SELLERS_SUCCESS: "GET_SELLERS_SUCCESS",
};

export const UPDATEUSER_DATA = {
  UPDATE_DATA: "UPDATE_DATA",
  UPDATE_DATA_SUCCESS: "UPDATE_DATA_SUCCESS",
  UPDATE_DATA_FAILED: "UPDATE_DATA_FAILED",
};
export const DELETEUSER_DATA = {
  DELETE_DATA: "DELETE_DATA",
  DELETE_DATA_SUCCESS: "DELETE_DATA_SUCCESS",
  DELETE_DATA_FAILED: "DELETE_DATA_FAILED",
};

export const ALL_REGION = {
  GET_REGIONS_FETCH: "GET_REGIONS_FETCH",
  GET_REGIONS_SUCCESS: "GET_REGIONS_SUCCESS",
};
