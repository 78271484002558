import React from "react";
import Card from "./card";
import Grid from "@material-ui/core/Grid";

const ProductList = (props) => {
  const fetchedProducts = props.products.map((product) => {
    return <Card key={product.id} product={product} />;
  });

  return (
    <Grid container justify="center">
      {fetchedProducts}
    </Grid>
  );
};

export default ProductList;
