import React, { useEffect, useState } from "react";
import Appbar from "./appbar";
import { useHistory } from "react-router-dom";
import CustomizedTables from "./table";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Link } from "react-router-dom";
import MuiDialog from "./dialog";
import AddUser from "./addUser";

export default function Users() {
  const history = useHistory();
  const localStorageToken = localStorage.getItem("token");
  useEffect(() => {
    if (localStorageToken === null) history.push("/");
  }, [localStorageToken]);

  const [dialogState, setDialogState] = useState(false);

  function handleDialogState() {
    setDialogState(!dialogState);
  }

  return (
    <div>
      <Appbar />

      <div style={{ display: "flex", margin: "3.5%" }}>
        <Typography variant="h4">
          Users
        </Typography>
        <MuiDialog
          title=""
          open={dialogState}
          maxWidth="lg"
          onClose={handleDialogState}
        >
          <AddUser handleDialogState={handleDialogState} />
        </MuiDialog>
        <Button
          style={{ marginLeft: "auto" }}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<PersonAddIcon />}
          onClick={handleDialogState}
        >
          Add User
        </Button>
      </div>

      <CustomizedTables />
    </div>
  );
}
