import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(-3),
    },
    root: {
        zIndex: '888 !important',
    },
}));

export default function MuiDialog({
    onClose,
    open,
    title,
    fullWidth,
    maxWidth,
    children,
}) {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            className={classes.root}
        >
            <DialogTitle id="form-dialog-title" className={classes.title}>
                <Box textAlign="right" mt={-5}>
                    <IconButton onClick={onClose}>
                        <CloseIcon color="secondary" />
                    </IconButton>
                </Box>
                <Box mt={-8}>
                    <Typography variant="h4">
                        {title == '' ? '' : title}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
}

MuiDialog.propTypes = {
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.element,
};
MuiDialog.defaultProps = {
    fullWidth: true,
    maxWidth: 'sm',
};