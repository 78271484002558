import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../images/logo.JPG";

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: "auto",
    marginTop: "3px",
  },
  spaceing: {
    marginTop: "5px",
  },
}));
export default function Header() {
  const classes = useStyles();
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5">
            <img src={Logo} className={classes.spaceing} />
          </Typography>
          <div>&nbsp;</div>
          <Typography variant="h5">DASHBOARD</Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
