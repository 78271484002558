import React from "react";
import Card from "./card";
import Grid from "@material-ui/core/Grid";

const OffersList = (props) => {
  const fetchedOffers = props.offers.map((offer) => {
    return <Card key={offer.id} offer={offer} />;
  });

  return (
    <Grid container justify="center">
      {fetchedOffers}
    </Grid>
  );
};

export default OffersList;
