import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: blue[800],
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  overrides: {
    MuiTextField: {
      root: {
        letterSpacing: ".05rem",
      },
    },
    MuiButton: {
      root: {
        textTransform: "uppercase",
        letterSpacing: ".1rem",
      },
    },
    MuiTypography: {
      root: {
        letterSpacing: ".05rem",
      },
    },
  },
});

export default theme;
