import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Header from "./header";
import { useSelector, useDispatch } from "react-redux";
import { getcredentials } from "../actions/index";
import { useFormik, Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { getStateToken, getStateErr } from "../selectors/selector";
import "../App.css";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errs: {
    color: "red",
  },
}));

export default function LogIn() {
  const history = useHistory();
  const error = useSelector(getStateErr);
  const userData = useSelector(getStateToken);
  const classes = useStyles();
  const dispatch = useDispatch();
  var localStorageToken;

  useEffect(() => {
    localStorageToken = localStorage.getItem("token");
    if (
      localStorageToken &&
      typeof localStorageToken !== "undefined" &&
      localStorageToken !== null &&
      localStorageToken !== ""
    ) {
      history.push("/offers");
    }
  }, [error, localStorageToken, userData]);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("username is required"),
      password: Yup.string().required("password is required"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("username", values.username);
      formData.append("password", values.password);
      dispatch(getcredentials(formData));
    },
  });
  return (
    <div>
      <Header />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Admin Login
          </Typography>
          <Typography>
            {error && <div className="error">{error}</div>}
          </Typography>
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={Yup.object({
              username: Yup.string().required("username is required"),
              password: Yup.string().required("password is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                const formData = new FormData();
                formData.append("username", values.username);
                formData.append("password", values.password);
                dispatch(getcredentials(formData));
                setSubmitting(false);
              }, 400);
            }}
          >
            {(formik) => (
              <Form className={classes.form} onSubmit={formik.handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  {...formik.getFieldProps("username")}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className={classes.errs}>{formik.errors.username}</div>
                ) : null}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className={classes.errs}>{formik.errors.password}</div>
                ) : null}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign In
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
}
