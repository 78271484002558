import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  addUser,
  getSellersFetch,
  getRegionsFetch,
  initallAddUserState,
  loadScrapper,
} from "../actions/index";
import { useFormik, Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import {
  getRegions,
  getSellers,
  getStateAddUser,
  getStateAddUserErr,
} from "../selectors/selector";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "../App.css";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errs: {
    color: "red",
  },
}));

export default function AddUser({ handleDialogState }) {
  const history = useHistory();
  const error = useSelector(getStateAddUserErr);
  const userData = useSelector(getStateAddUser);
  const user = useSelector(getSellers);
  const region = useSelector(getRegions);
  const regions = useSelector(getRegions);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [errorMessage = {}] = error || [];
  const { message = "" } = errorMessage;
  const [selectedOptionSeller, setselectedOptionSeller] = useState(null);
  const [seller, setSeller] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);

  const handleRegionChange1 = (event) => {
    setSelectedRegions(event.target.value);
  };
  useEffect(() => {
    if (userData["status"] === 201) {
      handleDialogState();
      dispatch(initallAddUserState());
      dispatch(loadScrapper(1));
      dispatch(getRegionsFetch());
    }
  }, [userData]);
  const handleInputChange = (event, value) => {
    const selectedOption = user.data.find((option) => option.name === value);
    const selectedOptionId = selectedOption?.id;
    setselectedOptionSeller(selectedOptionId);
    setSeller(selectedOptionId);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      passwordConfirmation: "",
      seller: "",
      regions: [],
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("username is required")
        .email("Invalid email"),
      password: Yup.string().required("Password is required"),
      passwordConfirmation: Yup.string()
        .required("Confirm Password is required")
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.password === value;
        }),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("username", values.username);
      formData.append("password", values.password);
      formData.append("passwordConfirmation", values.passwordConfirmation);
      formData.append("seller", seller);
      formData.append("regions", selectedRegions);
      dispatch(addUser(formData));
    },
  });
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Add User
          </Typography>
          <Typography>
            {message && <div className="error">{message}</div>}
          </Typography>
          <Formik
            initialValues={{
              username: "",
              password: "",
              passwordConfirmation: "",
              seller: "",
            }}
            validationSchema={Yup.object({
              username: Yup.string()
                .required("username is required")
                .email("Invalid email"),
              password: Yup.string().required("Password is required"),
              passwordConfirmation: Yup.string()
                .required("Confirm Password is required")
                .test(
                  "passwords-match",
                  "Passwords must match",
                  function (value) {
                    return this.parent.password === value;
                  }
                ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                const formData = new FormData();
                formData.append("username", values.username);
                formData.append("password", values.password);
                formData.append(
                  "passwordConfirmation",
                  values.passwordConfirmation
                );
                formData.append("seller", seller);
                formData.append("regions", selectedRegions);

                dispatch(addUser(formData));
                setSubmitting(false);
              }, 400);
            }}
          >
            {(formik) => (
              <Form className={classes.form} onSubmit={formik.handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  {...formik.getFieldProps("username")}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className={classes.errs}>{formik.errors.username}</div>
                ) : null}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  // autoComplete="current-password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className={classes.errs}>{formik.errors.password}</div>
                ) : null}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="passwordConfirmation"
                  label="Confirm Password"
                  type="password"
                  id="passwordConfirmation"
                  // autoComplete="current-password"
                  {...formik.getFieldProps("passwordConfirmation")}
                />
                <Autocomplete
                  options={user.data}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) =>
                    handleInputChange(event, value?.name)
                  }
                  renderInput={(params) => (
                    <TextField
                      value={selectedOptionSeller}
                      {...params}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      label="Seller"
                    />
                  )}
                />
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  // style={{ width: "100%", margin: "normal" }}
                >
                  <InputLabel fullWidth id="region-selector-label">
                    Region
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="region-selector-label"
                    id="region-selector"
                    label="Region"
                    multiple
                    value={selectedRegions}
                    onChange={handleRegionChange1}
                    renderValue={(selectedRegions) =>
                      selectedRegions
                        .map(
                          (id) =>
                            regions?.data?.results?.find(
                              (region) => region.id === id
                            )?.name
                        )
                        .join(", ")
                    }
                  >
                    {regions?.data?.results?.map((region) => (
                      <MenuItem fullWidth key={region.id} value={region.id}>
                        <Checkbox
                          fullWidth
                          checked={selectedRegions.indexOf(region.id) > -1}
                        />
                        <ListItemText primary={region.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation ? (
                  <div className={classes.errs}>
                    {formik.errors.passwordConfirmation}
                  </div>
                ) : null}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
}
