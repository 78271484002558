import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../images/logo.JPG";
import ExitToAppIcon from "@material-ui/icons/PowerSettingsNew";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import OffersIcon from "@material-ui/icons/LocalOffer";
import ProductsIcon from "@material-ui/icons/RvHookup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../actions";

const useStyles = makeStyles(() => ({
  navigationItem: {
    marginRight: 10,
  },
  spacing: {
    marginTop: "5px",
    marginRight: 20,
  },
  icon: {
    minWidth: "30px",
  },
  right: { marginLeft: "auto" },
  margin:
  {
    marginLeft: "10px",
    marginRight: "10px",
  }
}));
export default function Appbar() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
    dispatch(logout());
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5">
            <img src={Logo} className={classes.spacing} />
          </Typography>
          <div>&nbsp;</div>
          {/* <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/"
            className={classes.navigationItem}
            startIcon={<DashboardIcon />}
          >
            Dashboard
          </Button> */}
          <Button
            label="RV"
            color="secondary"
            variant="contained"
            onClick={handleClick}
          >
            RV mapping
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              component={Link}
              to="/products"
              key="products"
              value="products"
            >
              <ListItemIcon className={classes.icon}>
                <ProductsIcon fontSize="small" />
              </ListItemIcon>
              PRODUCTS
            </MenuItem>
            <MenuItem component={Link} to="/offers" key="offers" value="offers">
              <ListItemIcon className={classes.icon}>
                <OffersIcon fontSize="small" />
              </ListItemIcon>
              OFFERS
            </MenuItem>
          </Menu>
          <div className={classes.right}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.margin}
              component={Link}
              to="/users"
              key="users"
              value="users"
            >
              <AccountBoxIcon />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogout}
              className={classes.margin}
            >
              <ExitToAppIcon />
            </Button>
          </div>
          {/* </div> */}
        </Toolbar>
      </AppBar>
    </div>
  );
}
