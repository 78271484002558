import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CreateIcon from "@material-ui/icons/Create";
import BlockIcon from "@material-ui/icons/Block";
import UnblockIcon from "@material-ui/icons/Check";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Spinner from "../../../../UI/spinner";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  divider: {
    padding: "10px 0px",
  },
  error: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    textTransform: "uppercase",
    color: "red",
  },
  blockButton: {
    marginTop: 10,
  },
}));

const PrimaryDetails = (props) => {
  const classes = useStyles();
  const {
    offer,
    createProduct,
    disableCreateProduct,
    createError,
    createLoading,
    updateLoading,
    updateError,
    updateOffer,
  } = props;

  let attributes = [];
  let id = 0;

  for (const [key, value] of Object.entries(offer.data)) {
    let attribute = key.replace(/\_/g, " ").toUpperCase();
    if (attribute.startsWith("RV ")) attribute = attribute.substring(3);
    if (
      attribute !== "CREATED AT" &&
      attribute !== "UPDATED AT" &&
      attribute !== "OFFER" &&
      attribute !== "SALE PRICE" &&
      attribute !== "RETAIL PRICE"
    ) {
      attributes.push(
        <Typography key={id} variant="body1" gutterBottom>
          {attribute}: <strong>{value ? value : "NULL"}</strong>
        </Typography>
      );
      id = id + 1;
    }
    if (attribute === "SALE PRICE" ||
      attribute === "RETAIL PRICE") {
      let val = 0;
      if (value == 99999999)
        val = null;
      else
        val = value;
      attributes.push(
        <Typography key={id} variant="body1" gutterBottom>
          {attribute}: <strong>{val ? val : "NULL"}</strong>
        </Typography>
      );
      id = id + 1;
    }
  }
  const blockIcon = offer.blocked ? <UnblockIcon /> : <BlockIcon />;
  return (
    <div className={classes.main}>
      <Typography variant="h5" gutterBottom>
        <Link href={offer?.url} target="_blank">
          {offer.title.toUpperCase()}
        </Link>
      </Typography>
      {createLoading ? (
        <Spinner />
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={createProduct}
          disabled={disableCreateProduct}
          startIcon={<CreateIcon />}
        >
          CREATE RV PRODUCT
        </Button>
      )}
      {updateLoading ? (
        <Spinner />
      ) : (
        <div className={classes.blockButton}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={updateOffer}
            //   disabled={disableCreateProduct}
            startIcon={blockIcon}
          >
            {!offer.blocked ? "BLOCK OFFER" : "UNBLOCK OFFER"}
          </Button>
        </div>
      )}
      {createError && (
        <div className={classes.error}>
          <Typography variant="body1">{createError}</Typography>
        </div>
      )}
      {updateError && (
        <div className={classes.error}>
          <Typography variant="body1">{updateError}</Typography>
        </div>
      )}
      <div className={classes.divider}>
        <Divider />
      </div>
      {attributes}
    </div>
  );
};

export default PrimaryDetails;
