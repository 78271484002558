import {
  SCRAPPERDATA,
  LOGINDATA,
  ADDUSERDATA,
  PRODUCTSDATA,
  OFFERSDATA,
  PRODUCT_INFO,
  ALL_SELLER,
  ALL_REGION,
  UPDATEUSER_DATA,
  DELETEUSER_DATA,
} from "../constants/index";
import {
  setError,
  getData,
  getuserdata,
  getadduserdata,
  getadduserError,
  setLoginError,
  addPageData,
  requestProductsSuccess,
  requestProductsError,
  requestProductSuccess,
  requestProductError,
  requestOffersSuccess,
  requestOffersError,
  requestOfferSuccess,
  requestOfferError,
  requestMappedProductSuccess,
  requestMappedProductError,
  mapOffersError,
  mapOffersSuccess,
  createProductError,
  createProductSuccess,
  deleteProductError,
  deleteProductSuccess,
  updateOfferError,
  updateOfferSuccess,
  getBrandListSuccess,
  getBrandListError,
  getModelListSuccess,
  getModelListError,
  getVariantListSuccess,
  getVariantListError,
  updateProductFailed,
  updateProductSucess,
  getSellersFetch,
  getSellerSuccess,
  getRegionsFetch,
  getRegionsSuccess,
  updateUser,
  updateUserSuccess,
  deleteUserSuccess,
  deleteUserFailed,
} from "../actions/index";
import { takeEvery, call, put } from "redux-saga/effects";
import axiosInstance from "../axiosInstance";
import { URL, URLCOUNT, URLLOGIN, URLREFRESH, URLPRODUCTS } from "../api/index";
import { Modal as Modal_swal } from "../utils/helper";

export default function* watchDataLoad() {
  yield takeEvery(SCRAPPERDATA.LOAD, handleDataLoad);
  yield takeEvery(LOGINDATA.LOGIN_LOADING, handleLogin);
  yield takeEvery(ADDUSERDATA.ADDUSER_LOADING, handleAddUser);
  yield takeEvery(PRODUCTSDATA.REQUEST_PRODUCTS, handleRequestProducts);
  yield takeEvery(PRODUCTSDATA.REQUEST_PRODUCT, handleRequestProduct);
  yield takeEvery(OFFERSDATA.REQUEST_OFFERS, handleRequestOffers);
  yield takeEvery(OFFERSDATA.REQUEST_OFFER, handleRequestOffer);
  yield takeEvery(OFFERSDATA.SEARCH_OFFERS, handleSearchOffers);
  yield takeEvery(
    OFFERSDATA.REQUEST_MAPPED_PRODUCT,
    handleRequestMappedProduct
  );
  yield takeEvery(PRODUCTSDATA.MAP_OFFERS, handleMapOffers);
  yield takeEvery(OFFERSDATA.CREATE_PRODUCT, handleCreateProduct);
  yield takeEvery(PRODUCTSDATA.DELETE_PRODUCT, handleDeleteProduct);
  yield takeEvery(OFFERSDATA.UPDATE_OFFER, handleUpdateOffer);
  yield takeEvery(PRODUCT_INFO.BRANDS_LIST, hanleGetBrands);
  yield takeEvery(PRODUCT_INFO.MODEL_LIST, hanleGetModels);
  yield takeEvery(PRODUCT_INFO.VARIANT_LIST, hanleGetVariants);
  yield takeEvery(PRODUCTSDATA.UPDATE_PRODUCT, handleUpdateProduct);
  yield takeEvery(ALL_SELLER.GET_SELLERS_FETCH, handlGetSellers);
  yield takeEvery(ALL_REGION.GET_REGIONS_FETCH, handlGetRegions);

  yield takeEvery(UPDATEUSER_DATA.UPDATE_DATA, handlUpdateUser);
  yield takeEvery(DELETEUSER_DATA.DELETE_DATA, handlDeleteUser);
}

function fetchProducts(params) {
  let urlParams = "";
  if ("page" in params) urlParams = urlParams + "?page=" + params.page;
  else urlParams = urlParams + "?page=1";
  if ("filter" in params) urlParams = urlParams + "&filter=" + params.filter;
  if ("title" in params) urlParams = urlParams + "&name=" + params.title;
  if ("sort" in params) urlParams = urlParams + "&sort=" + params.sort;
  if ("brand" in params) urlParams = urlParams + "&brand=" + params.brand;
  if ("hasBrand" in params)
    urlParams = urlParams + "&hasBrand=" + params.hasBrand;
  if ("model" in params) urlParams = urlParams + "&model=" + params.model;
  if ("hasModel" in params)
    urlParams = urlParams + "&hasModel=" + params.hasModel;
  if ("variant" in params) urlParams = urlParams + "&variant=" + params.variant;
  if ("hasVariant" in params)
    urlParams = urlParams + "&hasVariant=" + params.hasVariant;
  if ("no_pagination" in params)
    urlParams = urlParams + "&no_pagination=" + params.no_pagination;

  const token = localStorage.getItem("token");
  return axiosInstance.get("/faceted-search" + urlParams, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* handleRequestProducts(payload) {
  try {
    const response = yield call(fetchProducts, payload.params);
    if (response) {
      const data = response.data;
      yield put(requestProductsSuccess(data));
    }
  } catch (err) {
    let errorText = "Failed to fetch RVs";
    if (err.response) errorText = err.response.statusText;
    yield put(requestProductsError(errorText));
  }
}

function fetchProduct(id) {
  const token = localStorage.getItem("token");
  return axiosInstance.get("/products/" + id, {
    headers: {
      Authorization: `  Token ${token}`,
    },
  });
}

function* handleRequestProduct(payload) {
  try {
    const response = yield call(fetchProduct, payload.id);
    if (response) yield put(requestProductSuccess(response.data));
  } catch (err) {
    let errorText = "Failed to fetch Product";
    if (err.response) errorText = err.response.statusText;
    yield put(requestProductError(errorText));
  }
}

function mapOffers(offers, product, query) {
  const token = localStorage.getItem("token");
  return axiosInstance.post(
    "/products/mapoffers/",
    { offers, product, query },
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  );
}

function* handleMapOffers(payload) {
  try {
    const response = yield call(
      mapOffers,
      payload.offers,
      payload.product,
      payload.query
    );
    if (response) yield put(mapOffersSuccess(response.data));
  } catch (err) {
    let errorText = "Failed to Map Offers";
    if (err.response) errorText = err.response.statusText;
    yield put(mapOffersError(errorText));
  }
}

function fetchOffers(params) {
  let urlParams = "";
  if ("page" in params) urlParams = urlParams + "?page=" + params.page;
  else urlParams = urlParams + "?page=1";
  if ("filter" in params) urlParams = urlParams + "&filter=" + params.filter;
  if ("title" in params) urlParams = urlParams + "&title=" + params.title;
  if ("vendor" in params) urlParams = urlParams + "&seller=" + params.vendor;
  if ("vendorContains" in params)
    urlParams = urlParams + "&sellerContains=" + params.vendorContains;
  if ("sort" in params) urlParams = urlParams + "&sort=" + params.sort;
  if ("last_seen" in params) {
    let last_seen = params.last_seen;
    last_seen =
      last_seen.getFullYear() +
      "-" +
      (last_seen.getMonth() + 1) +
      "-" +
      last_seen.getDate();
    urlParams = urlParams + "&last_seen=" + last_seen;
  }

  const token = localStorage.getItem("token");
  return axiosInstance.get("/offers" + urlParams, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* handleRequestOffers(payload) {
  try {
    const response = yield call(fetchOffers, payload.params);
    if (response) {
      const data = response.data;
      yield put(requestOffersSuccess(data));
    }
  } catch (err) {
    let errorText = "Failed to fetch RV Offers";
    if (err.response) errorText = err.response.statusText;
    yield put(requestOffersError(errorText));
  }
}

function fetchOffer(id) {
  const token = localStorage.getItem("token");
  return axiosInstance.get("/offers/" + id, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* handleRequestOffer(payload) {
  try {
    const response = yield call(fetchOffer, payload.id);
    if (response) yield put(requestOfferSuccess(response.data));
  } catch (err) {
    let errorText = "Failed to fetch Offer";
    if (err.response) errorText = err.response.statusText;
    yield put(requestOfferError(errorText));
  }
}

function updateOffer(id, data) {
  const token = localStorage.getItem("token");
  return axiosInstance.patch("/offers/" + id + "/", data, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* handleUpdateOffer(payload) {
  try {
    const response = yield call(updateOffer, payload.id, payload.data);
    if (response) yield put(updateOfferSuccess(response.data));
  } catch (err) {
    let errorText = "Failed to Update Offer";
    if (err.response) errorText = err.response.statusText;
    yield put(updateOfferError(errorText));
  }
}

function searchOffers(params) {
  const token = localStorage.getItem("token");
  return axiosInstance.get(
    "/offers/search?json_query=" + encodeURIComponent(params.query),
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  );
}

function* handleSearchOffers(payload) {
  try {
    const response = yield call(searchOffers, payload.params);
    if (response) yield put(requestOffersSuccess(response.data));
  } catch (err) {
    let errorText = "Failed to search Offers";
    if (err.response) errorText = err.response.statusText;
    yield put(requestOffersError(errorText));
  }
}

function* handleRequestMappedProduct(payload) {
  try {
    const response = yield call(fetchProduct, payload.id);
    if (response) yield put(requestMappedProductSuccess(response.data));
  } catch (err) {
    let errorText = "Failed to fetch Mapped Product";
    if (err.response) errorText = err.response.statusText;
    yield put(requestMappedProductError(errorText));
  }
}

function createProduct(offer) {
  const token = localStorage.getItem("token");
  return axiosInstance.post(
    "/offers/createproduct/",
    { offer },
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  );
}

function* handleCreateProduct(payload) {
  try {
    const response = yield call(createProduct, payload.offer);
    if (response) yield put(createProductSuccess(response.data));
  } catch (err) {
    let errorText = "Failed to Create Product";
    if (err.response) errorText = err.response.statusText;
    yield put(createProductError(errorText));
  }
}

function deleteProduct(id) {
  const token = localStorage.getItem("token");
  return axiosInstance.delete("/products/" + id, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* handleDeleteProduct(payload) {
  try {
    const response = yield call(deleteProduct, payload.id);
    if (response) yield put(deleteProductSuccess());
  } catch (err) {
    let errorText = "Failed to Delete Product";
    if (err.response) errorText = err.response.statusText;
    yield put(deleteProductError(errorText));
  }
}

function* handleDataLoad(payload) {
  try {
    console.log("payload", payload);
    if (payload && payload.page) {
      const data = yield call(
        fetchData,
        // payload.date,
        // payload.stat,
        payload.page
      );
      // const count = yield call(fetchCounts, payload.date);
      const pageData = data;

      // const _data = {
      //   data: data,
      //   // count: count?.result,
      //   date: payload.date,
      //   _state: payload.stat,
      //   page: payload.page,
      // };_data

      yield put(getData({ data: pageData }));
    }
  } catch (error) {
    yield put(setError(error.toString()));
  }
}

const fetchData = async (page) => {
  let requestURL = ``;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Token ${token}`,
  };
  requestURL = `${URL}?page=${page}`;
  const response = await fetch(requestURL, { headers });

  const data = await response.json();

  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};
const fetchCounts = async (date) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Token ${token}`,
  };
  const requestURL = `${URLCOUNT}?date=${date}`;

  const response = await fetch(requestURL, { headers });
  const data = await response.json();

  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};
function* handleLogin({ payload }) {
  // try {

  if (payload) {
    const {
      data: { token = "" },
    } = yield call(fetchUserData, payload);
    localStorage.setItem("token", token);

    yield put(getuserdata(token));
  }
}

const fetchToken = async (payload) => {
  var data;
  const formData = new FormData();
  formData.append("refresh", payload);

  return data;
};

const fetchUserData = async (payload) => {
  var data;
  const result = await axiosInstance
    .post(URLLOGIN, payload)
    .then((res) => {
      data = res;
    })
    .catch((err) => {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.detail
      ) {
        if (err.response.status == 401) {
          data = err.response.data.detail;
        } else if (err.response.status == 500) {
          data = err.response.data.detail;
        } else {
          // anything else
        }
      }
    });
  return data;
};

const addUser = async (payload) => {
  // console.log("payload", payload.get("seller"));
  var data;
  payload = {
    email: payload.get("username").toLowerCase(),
    password: payload.get("password"),
    username: payload.get("username").split("@")[0].toLowerCase(),
    seller: payload.get("seller"),
    regions: payload.get("regions").split(","),
    role: 1,
  };
  const result = await axiosInstance
    .post(URL, payload, {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      data = res;
    })
    .catch((err) => {
      const {
        response: {
          data: { errors },
        },
      } = err;
      data = errors;
    });

  return data;
};

function* handleAddUser({ payload }) {
  if (payload) {
    const data = yield call(addUser, payload);
    if (!data.length) {
      yield put(getadduserdata(data));
    } else {
      yield put(getadduserError(data));
    }
  }
}

function getBrands() {
  const token = localStorage.getItem("token");
  return axiosInstance.get("/brands/", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* hanleGetBrands() {
  try {
    const response = yield call(getBrands);
    yield put(getBrandListSuccess(response?.data));
  } catch (error) {
    yield put(getBrandListError(error));
  }
}

function getModels() {
  const token = localStorage.getItem("token");
  return axiosInstance.get("/models/", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* hanleGetModels() {
  try {
    const response = yield call(getModels);
    yield put(getModelListSuccess(response?.data));
  } catch (error) {
    yield put(getModelListError(error));
  }
}

function getVariants() {
  const token = localStorage.getItem("token");
  return axiosInstance.get("/variants/", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* hanleGetVariants() {
  try {
    const response = yield call(getVariants);
    yield put(getVariantListSuccess(response?.data));
  } catch (error) {
    yield put(getVariantListError(error));
  }
}
function UpdateTheProduct(payload) {
  const { payload: { product = "", payload: productData = {} } = {} } = payload;
  const token = localStorage.getItem("token");
  return axiosInstance.patch(`/products/${product}/`, productData, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* handleUpdateProduct(payload) {
  try {
    const response = yield call(UpdateTheProduct, payload);
    const { data = {} } = response;
    yield put(updateProductSucess(data));
  } catch (error) {
    yield put(updateProductFailed(error));
  }
}

function getSellers(params) {
  const token = localStorage.getItem("token");
  return axiosInstance.get("/seller/?no_pagination=true", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* handlGetSellers(payload) {
  try {
    const response = yield call(getSellers, payload.params.no_pagination);
    yield put(getSellerSuccess(response?.data));
  } catch (error) {
    yield put(getVariantListError(error));
  }
}

const updateUserData = async (payload) => {
  var data;
  const id = payload?.payload?.id;

  payload = {
    seller: parseInt(payload?.payload?.seller),
    regions: payload?.payload?.regions,
  };
  const result = await axiosInstance
    .put(URL + id + "/", payload, {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      data = res;
    })
    .catch((err) => {
      const {
        response: {
          data: { errors },
        },
      } = err;
      data = errors;
    });

  return data;
};

function* handlUpdateUser(payload) {
  try {
    const response = yield call(updateUserData, payload);
    yield put(updateUserSuccess(response?.data));
  } catch (error) {
    console.log(error);
  }
}

const deleteUser = async (payload) => {
  const id = payload?.payload?.id;
  try {
    const response = await axiosInstance.delete(URL + id + "/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    if (response.status == 320) {
      throw new Error(error);
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    if (error === "Request failed with status code 320") {
      throw new Error(error);
    } else {
      throw err;
    }
  }
};

function* handlDeleteUser(payload) {
  try {
    const response = yield call(deleteUser, payload);
    yield put(deleteUserSuccess(response));
    Modal_swal.fire({
      title: "User Deleted",
      text: "User Has been Deleted.",
      icon: "success",
    });
    dispatch(loadScrapper(1));
  } catch (error) {
    if (error.message === "Request failed with status code 320") {
      Modal_swal.fire({
        title: "Error",
        text: "Superusers cannot be deleted.",
        icon: "error",
      });
    } else {
      yield put(deleteUserFailed(error));
    }
  }
}

function getRegions(params) {
  const token = localStorage.getItem("token");

  return axiosInstance.get("/regions", {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

function* handlGetRegions(payload) {
  try {
    const response = yield call(getRegions, payload.params.no_pagination);

    yield put(getRegionsSuccess(response?.data));
  } catch (error) {
    yield put(getVariantListError(error));
  }
}
