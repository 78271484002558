import { LOGINDATA } from "../constants/index";
const loginState = {
  loading: false,
  err: "",
  success: false,
  userData: [],
};
export const LogInReducer = (state = loginState, action) => {
  switch (action.type) {
    case LOGINDATA.LOGIN_LOADING:
      return {
        ...state,
        loading: true,
        err: null,
        success: false,
      };
    case LOGINDATA.LOGIN_DATA:
      return {
        ...state,
        loading: false,
        err: null,
        userData: action.payload,
        success: true,
        // username: action.payload.username,
        // is_superuser: action.payload.is_superuser,
      };
    case LOGINDATA.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        err: action.error,
        success: false,
      };
    case LOGINDATA.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        err: action.error,
        success: false,
      };
    default:
      return state;
  }
};
