import { ADDUSERDATA } from "../constants/index";
const addUserState = {
  loading: false,
  err: "",
  success: false,
  userData: [],
};
export const AddUserReducer = (state = addUserState, action) => {
  switch (action.type) {
    case ADDUSERDATA.ADDUSER_LOADING:
      return {
        ...state,
        loading: true,
        err: null,
        success: false,
      };
    case ADDUSERDATA.ADDUSER_DATA:
      return {
        ...state,
        loading: false,
        err: null,
        userData: action.payload,
        success: true,
      };
    case ADDUSERDATA.ADDUSER_FAIL:
      return {
        ...state,
        loading: false,
        err: action.error,
        success: false,
      };
    case ADDUSERDATA.ADDUSER_INIT:
      return {
        ...state,
        loading: false,
        err: "",
        success: false,
        userData: [],
      }

    default:
      return state;
  }
};
