import { ALL_SELLER } from "../constants";

const scrapperState = {
  data: [],
  error: "",
  loading: true,
  success: false,
};

export const SellerReducer = (state = scrapperState, action) => {
  switch (action.type) {
    case ALL_SELLER.GET_SELLERS_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case ALL_SELLER.GET_SELLERS_SUCCESS:
      return {
        ...state,
        data: action?.payload,
        loading: false,
        error: null,
        success: true,
      };
    default:
      return state;
  }
};
