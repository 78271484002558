import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
}));

const Spinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <CircularProgress thickness={5} color="secondary" />
    </div>
  );
};

export default Spinner;
