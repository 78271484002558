import React, { useEffect } from "react";
import Appbar from "../../appbar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import List from "./list";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import Filters from "./filters";
import Spinner from "../../../UI/spinner";
import Divider from "@material-ui/core/Divider";
import { resetProducts } from "../../../actions";
import {
  getProductsData,
  getProductsError,
  getProductsLoading,
} from "../../../selectors/selector";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: 10,
  },
  errorMessage: {
    display: "flex",
    justifyContent: "center",
    letterSpacing: ".1rem",
    textTransform: "uppercase",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Products = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(getProductsLoading);
  const error = useSelector(getProductsError);
  const products = useSelector(getProductsData);

  const localStorageToken = localStorage.getItem("token");
  useEffect(() => {
    if (localStorageToken === null) history.push("/");
  }, [localStorageToken]);

  useEffect(() => {
    return () => {
      dispatch(resetProducts());
    };
  }, [resetProducts]);

  let fetchedProducts = null;
  if (error)
    fetchedProducts = <h2 className={classes.errorMessage}>{error}</h2>;
  if (loading)
    fetchedProducts = (
      <Backdrop className={classes.backdrop} open={loading}>
        <Spinner />
      </Backdrop>
    );
  if (!error && !loading)
    fetchedProducts = (
      <h2 className={classes.errorMessage}>No RV Product Found</h2>
    );

  if (
    products.data?.results !== undefined &&
    products.data?.results.length !== 0 &&
    !loading
  )
    fetchedProducts = <List products={products.data?.results.results} />;


  return (
    <>
      <Appbar />
      <Grid
        className={classes.main}
        container
        direction="row"
        justify="space-around"
      >
        <Grid item xs={3}>
          <Filters
            pagesCount={products.data?.results.total_pages}
            brandsList={products.data?.brands_counts}
            modelsList={products.data?.models_counts}
            variantsList={products.data?.variants_counts}
            productsCount={products.data?.products_counts}
            duration={products.data?.duration}
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={8}>
          {fetchedProducts}
        </Grid>
      </Grid>
    </>
  );
};

export default Products;
