import { DELETEUSER_DATA } from "../constants";

const addUserState = {
  loading: false,
  err: "",
  success: false,
  userData: [],
};
export const DeleteUserReducer = (state = addUserState, action) => {
  switch (action.type) {
    case DELETEUSER_DATA.DELETE_DATA:
      return {
        ...state,
        loading: true,
        err: null,
        success: false,
      };
    case DELETEUSER_DATA.DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        err: null,
        userData: action.payload,
        success: true,
      };
    case DELETEUSER_DATA.DELETE_DATA_FAILED:
      return {
        ...state,
        loading: false,
        err: action.error,
        success: false,
      };

    default:
      return state;
  }
};
