import { colors } from "@material-ui/core";
import Swal from "sweetalert2";

export const Modal = Swal.mixin({
  title: "Are you sure?",
  text: "You won't be able to revert this!",
  icon: "warning",
  customClass: {
    popup: "popup-class",
  },
});
