import React, { useEffect, useCallback, useState } from "react";
import Appbar from "../../../appbar";
import * as constants from "../JSONFilter/constants";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import Pagination from "@material-ui/lab/Pagination";
import { createSelector } from "reselect";
import Spinner from "../../../../UI/spinner";
import PrimaryDetails from "./primaryDetails";
import JSONFilter from "../JSONFilter/JSONFilter";
import {
  getBrandList,
  getModelList,
  getVariantList,
  searchOffers,
  updateProduct,
} from "../../../../actions";
import List from "../../Offers/listPaginated";
import { makeStyles, Typography } from "@material-ui/core";
import { useHistory, Redirect } from "react-router-dom";
import {
  requestProduct,
  resetProduct,
  resetOffers,
  mapOffers,
  deleteProduct,
} from "../../../../actions";
import {
  getProductData,
  getProductsError,
  getProductsLoading,
  getOffersData,
  getOffersError,
  getOffersLoading,
  getMapOffersError,
  getMapOffersLoading,
  getDeleteProductError,
  getDeleteProductLoading,
  getDeleteProductSuccess,
  getProductBrands,
  getProductModels,
  getProductvariants,
} from "../../../../selectors/selector";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: 20,
  },
  offers: {},
  searchMessage: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0px",
  },
  errorMessage: {
    display: "flex",
    justifyContent: "center",
    letterSpacing: ".1rem",
    textTransform: "uppercase",
  },
  mapOffersButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  mapOffersError: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    textTransform: "uppercase",
    color: "red",
  },
}));

const ProductDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const productLoading = useSelector(getProductsLoading);
  const productError = useSelector(getProductsError);
  const product = useSelector(getProductData);
  const offersLoading = useSelector(getOffersLoading);
  const offersError = useSelector(getOffersError);
  const mapOffersLoading = useSelector(getMapOffersLoading);
  const mapOffersError = useSelector(getMapOffersError);
  const deleteProductLoading = useSelector(getDeleteProductLoading);
  const deleteProductError = useSelector(getDeleteProductError);
  const deleteSuccess = useSelector(getDeleteProductSuccess);
  const offers = useSelector(getOffersData);
  const brands = useSelector(getProductBrands);
  const models = useSelector(getProductModels);
  const variants = useSelector(getProductvariants);

  const offersViewFiltersData = (state) => state.OffersReducer.viewFilters;
  const getOffersViewFiltersData = createSelector(
    [offersViewFiltersData],
    (viewFilters) => viewFilters
  );
  const viewFilters = useSelector(getOffersViewFiltersData);
  const [currentPage, setCurrentPage] = useState(viewFilters.page);
  const paginate = useCallback((event, value) => {
    setCurrentPage(value);
  }, []);


  const localStorageToken = localStorage.getItem("token");
  useEffect(() => {
    if (localStorageToken === null) history.push("/");
  }, [localStorageToken]);

  function handleSearchOffers(fields) {
    const query = parseQuery(fields);
    const params = {
      query: JSON.stringify(query),
    };
    dispatch(searchOffers(params));
  }

  const [booleanOperator, setBooleanOperator] = useState(constants.AND);
  const [searchFields, setSearchFields] = useState([]);
  const parseQuery = (values) => {
    const data = [];
    values.forEach((field, index) => {
      let pushed = false;
      let attributeOperator = field.attributeOperator;
      let attribute = field.attribute;
      let value = field.value;
      let booleanOperator = field.booleanOperator;

      if (field.negation)
        attributeOperator = constants.NEGATION + attributeOperator;
      if (value !== "") {
        if (index !== 0) {
          if (
            values[index - 1].booleanOperator === constants.OR &&
            booleanOperator !== constants.AND
          ) {
            data[data.length - 1].or.push({
              [attributeOperator]: [attribute, value],
            });
            pushed = true;
          }
        }
        if (!pushed) {
          if (booleanOperator === constants.OR) {
            data.push({
              [booleanOperator]: [{ [attributeOperator]: [attribute, value] }],
            });
          } else {
            data.push({
              [attributeOperator]: [attribute, value],
            });
          }
        }
      }
    });
    return data;
  };

  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  useEffect(() => {
    dispatch(requestProduct(id));
    dispatch(getBrandList());
    dispatch(getModelList());
    dispatch(getVariantList());
  }, []);

  const handleSubmit = () => {
    let offersData = offers.result;
    let productData = product.result;
    if (offers.result === undefined) offersData = offers;
    const query = parseQuery(searchFields);
    dispatch(mapOffers(offersData, productData, query));
  };

  const handleDeleteProduct = () => {
    dispatch(deleteProduct(product.result.id));
  };

  const handleUpdateProduct = (payload) => dispatch(updateProduct(payload));

  useEffect(() => {
    return () => {
      dispatch(resetOffers());
      dispatch(resetProduct());
    };
  }, [resetOffers, resetProduct]);

  let pageContent = null;
  let fetchedOffers = null;
  if (productError)
    pageContent = <h2 className={classes.errorMessage}>{productError}</h2>;
  if (productLoading)
    pageContent = (
      <Backdrop className={classes.backdrop} open={productLoading}>
        <Spinner />
      </Backdrop>
    );
  if (offersLoading) fetchedOffers = <Spinner />;
  if (offersError)
    fetchedOffers = <h2 className={classes.errorMessage}>{offersError}</h2>;
  if (offers.result && offers.result.length !== 0 && !offersLoading) {
    const paginatedOffers = offers.result;
    const indexOfLastTodo = currentPage * 10;
    const indexOfFirstTodo = indexOfLastTodo - 10;
    const currentTodos = paginatedOffers.slice(indexOfFirstTodo, indexOfLastTodo);
    fetchedOffers = <List offers={currentTodos} />;
  }

  if (product && product.result.length !== 0 && !deleteSuccess)
    pageContent = (
      <Grid
        className={classes.main}
        container
        direction="row"
        justify="space-around"
      >
        <Grid item xs={3}>
          <PrimaryDetails
            brands={brands}
            models={models}
            variants={variants}
            product={product.result}
            handleUpdateProduct={handleUpdateProduct}
            deleteProduct={handleDeleteProduct}
            deleteError={deleteProductError}
            deleteLoading={deleteProductLoading}
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={8}>
          <>
            <JSONFilter
              query={
                product.result.offers_query !== ""
                  ? JSON.parse(product.result.offers_query)
                  : product.result.offers_query
              }
              booleanOperator={booleanOperator}
              searchFields={searchFields}
              setSearchFields={setSearchFields}
              setBooleanOperator={setBooleanOperator}
              parseQuery={parseQuery}
              handleSubmit={handleSearchOffers}
              product={product.result}
            />
            <div className={classes.searchMessage}>
              <Typography variant="h5">
                {(offers.result && offers.result.length === 0) ||
                  offers.length === 0
                  ? "NO OFFER FOUND"
                  : offers.result.length + " SEARCHED OFFERS"}
                <Divider />
              </Typography>
            </div>
            <div className={classes.pagination}>
              <Pagination
                className={classes.rootpage}
                count={Math.ceil(offers?.result?.length / 10)}
                color="primary"
                page={currentPage}
                onChange={paginate}
              />
            </div>
            <div className={classes.offers}>{fetchedOffers}</div>
            <div className={classes.mapOffersButton}>
              {mapOffersLoading ? (
                <Spinner />
              ) : (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={offersLoading || offersError || mapOffersLoading}
                    size="large"
                  >
                    MAP OFFERS
                  </Button>
                </>
              )}
            </div>
            {mapOffersError ? (
              <div className={classes.mapOffersError}>
                <Typography variant="h6">{mapOffersError}</Typography>
              </div>
            ) : null}
          </>
        </Grid>
      </Grid>
    );
  if (deleteSuccess) pageContent = <Redirect to="/products" />;

  return (
    <>
      <Appbar />
      {pageContent}
    </>
  );
};

export default ProductDetail;
