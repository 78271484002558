import React, { Fragment, Component } from "react";
import LogIn from "./components/login";
import Products from "./components/RV/Products/products";
import ProductDetail from "./components/RV/Products/Detail/detail";
import OfferDetail from "./components/RV/Offers/detail/detail";
import Offers from "./components/RV/Offers/offers";
import Users from "./components/users";
import configureStore from "./store/index";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const store = configureStore();
class App extends Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <Fragment>
                  <Route path="/" exact component={LogIn} />
                  <Route path="/products" exact component={Products} />
                  <Route
                    path="/products/details"
                    exact
                    component={ProductDetail}
                  />
                  <Route path="/offers" exact component={Offers} />
                  <Route path="/offers/details" exact component={OfferDetail} />
                  <Route path="/users" exact component={Users} />
                </Fragment>
              </Switch>
            </Router>
          </ThemeProvider>
        </Provider>
      </div>
    );
  }
}

export default App;
