import React, { useEffect } from "react";
import * as constants from "./constants";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import RemoveIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Button, Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  addIcon: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(7),
  },
  booleanOperator: {
    margin: theme.spacing(2),
    marginRight: theme.spacing(1),

    textAlign: "center",
  },
  topButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  searchFields: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  booleanOperatorTypo: {
    letterSpacing: ".1rem",
    marginRight: theme.spacing(3),
    textTransform: "uppercase",
  },
  buttonConfirmContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
}));

const attributeOperators = [
  {
    value: constants.BEGINS_WITH,
    label: "Begins With",
  },
  {
    value: constants.ENDS_WITH,
    label: "Ends With",
  },
  {
    value: constants.CONTAINS,
    label: "Contains",
  },
  {
    value: constants.EQUALS,
    label: "Equals",
  },
  {
    value: constants.GREATER_THAN,
    label: "Greater Than",
  },
  {
    value: constants.GREATER_THAN_EQUAL,
    label: "Greater Than Or Equal",
  },
  {
    value: constants.LESS_THAN,
    label: "Less Than",
  },
  {
    value: constants.LESS_THAN_EQUAL,
    label: "Less Than Or Equal",
  },
  {
    value: constants.MATCHES,
    label: "Matches",
  },
];

const attributes = [
  {
    value: constants.TITLE,
    label: "Title",
  },
  {
    value: "status",
    label: "Status",
  },
];

const booleanOperators = [
  {
    value: constants.AND,
    label: "AND",
  },
  {
    value: constants.OR,
    label: "OR",
  },
];

const negations = [
  {
    value: true,
    label: "True",
  },
  {
    value: false,
    label: "False",
  },
];

const JSONFilter = (props) => {
  const classes = useStyles();

  const {
    searchFields,
    booleanOperator,
    setSearchFields,
    setBooleanOperator,
    handleSubmit,
    product,
  } = props;

  function parseDataHelper(
    values,
    attributeOperator,
    boolOperator,
    parsedData
  ) {
    const attribute = values[0];
    let value = values[1];
    let negation = false;
    if (attributeOperator.startsWith(constants.NEGATION)) {
      negation = true;
      attributeOperator = attributeOperator.substring(4);
    }
    parsedData.push({
      booleanOperator: boolOperator,
      negation: negation,
      attributeOperator: attributeOperator,
      attribute: attribute,
      value: value,
    });
    return parsedData;
  }

  useEffect(() => {
    let parsedData = [];
    if (props.query) {
      props.query.forEach((x, index) => {
        const operator = Object.keys(x)[0];
        if (operator === constants.OR) {
          Object.values(x)[0].forEach((y, index) => {
            const attributeOperator = Object.keys(y)[0];
            const values = Object.values(y)[0];
            parsedData = parseDataHelper(
              values,
              attributeOperator,
              operator,
              parsedData
            );
          });
        } else {
          const values = Object.values(x)[0];
          parsedData = parseDataHelper(
            values,
            operator,
            constants.AND,
            parsedData
          );
        }
      });
    }
    if (parsedData.length === 0) {
      if (product.make)
        parsedData.push({
          attribute: "title",
          attributeOperator: "contains",
          booleanOperator: "and",
          negation: false,
          value: product.make.name,
        });
      if (product.status)
        parsedData.push({
          attribute: "title",
          attributeOperator: "contains",
          booleanOperator: "and",
          negation: false,
          value: product.status,
        });
    }
    setSearchFields(parsedData);
    handleSubmit(parsedData);
  }, []);

  function handleChangeBooleanOperator(event) {
    setBooleanOperator(event.target.value);
  }

  function handleChangeInput(i, event) {
    const values = [...searchFields];
    const { name, value } = event.target;
    values[i][name] = value;
    setSearchFields(values);
  }

  function handleAddInput() {
    const values = [...searchFields];
    values.push({
      booleanOperator: booleanOperator,
      negation: false,
      attributeOperator: constants.CONTAINS,
      attribute: constants.TITLE,
      value: "",
    });
    setSearchFields(values);
  }

  function handleRemoveInput(i) {
    const values = [...searchFields];
    values.splice(i, 1);
    setSearchFields(values);
  }

  function handleSearchSubmit(event) {
    event.preventDefault();
    const fields = [...searchFields];
    handleSubmit(fields);
  }

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <span className={classes.topButtons}>
          <TextField
            className={classes.booleanOperator}
            name="booleanOperator"
            select
            label="Boolean Operator"
            value={booleanOperator}
            color="primary"
            variant="outlined"
            onChange={handleChangeBooleanOperator}
          >
            {booleanOperators.map((booleanOperator) => (
              <MenuItem
                key={booleanOperator.value}
                value={booleanOperator.value}
              >
                {booleanOperator.label}
              </MenuItem>
            ))}
          </TextField>
          <Fab
            color="primary"
            className={classes.addIcon}
            onClick={handleAddInput}
          >
            <AddIcon />
          </Fab>
        </span>
        {searchFields.map((field, index) => {
          return (
            <div key={`${field}-${index}`} className={classes.searchFields}>
              <Divider />
              {field.booleanOperator !== constants.AND && (
                <Typography
                  variant="h6"
                  className={classes.booleanOperatorTypo}
                >
                  ||
                </Typography>
              )}

              <TextField
                name="negation"
                select
                label="Negation"
                value={field.negation}
                color="secondary"
                variant="outlined"
                onChange={(e) => handleChangeInput(index, e)}
              >
                {negations.map((negation) => (
                  <MenuItem key={negation.value} value={negation.value}>
                    {negation.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                name="attributeOperator"
                select
                label="Operator"
                value={field.attributeOperator}
                color="secondary"
                variant="outlined"
                onChange={(e) => handleChangeInput(index, e)}
              >
                {attributeOperators.map((operator) => (
                  <MenuItem key={operator.value} value={operator.value}>
                    {operator.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                name="attribute"
                select
                label="Attribute"
                value={field.attribute}
                color="secondary"
                variant="outlined"
                onChange={(e) => handleChangeInput(index, e)}
              >
                {attributes.map((attribute) => (
                  <MenuItem key={attribute.value} value={attribute.value}>
                    {attribute.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                name="value"
                label="Value"
                value={field.value}
                color="secondary"
                variant="outlined"
                onChange={(e) => handleChangeInput(index, e)}
              ></TextField>
              <IconButton
                color="primary"
                disableFocusRipple={true}
                onClick={() => handleRemoveInput(index)}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          );
        })}
        <div className={classes.divider}>
          <Divider />
        </div>
        <div className={classes.buttonConfirmContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSearchSubmit}
            size="large"
            type="submit"
          >
            Search
          </Button>
        </div>
      </form>
    </>
  );
};

export default JSONFilter;
