import React, { useState, useCallback, useEffect } from "react";
import { Divider, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "@material-ui/lab/Pagination";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { requestOffers, setOffersViewFilters } from "../../../actions";
import { getOffersViewFiltersData } from "../../../selectors/selector";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const filterMethod = [
  {
    value: "nofilter",
    label: "No Filter",
  },
  {
    value: "mapped",
    label: "Mapped Offers",
  },
  {
    value: "unmapped",
    label: "Unmapped Offers",
  },
  {
    value: "blocked",
    label: "Blocked Offers",
  },
];

let sortMethod = [
  {
    value: "default",
    label: "Default Sorting",
  },
  {
    value: "title",
    label: "Sort By Title",
  },
  {
    value: "price",
    label: "Sort By Price",
  },
  {
    value: "latest",
    label: "Sort By Latest",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  rootpage: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  pagination: {
    // marginTop: 10,
    marginBottom: 20,
  },
  main: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  checkBox: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Filters = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const viewFilters = useSelector(getOffersViewFiltersData);
  const [vendorContains, setVendorContains] = useState(
    viewFilters.vendorContains
  );
  const [filter, setFilter] = useState(viewFilters.filter);
  const [title, setTitle] = useState(viewFilters.title);
  const [vendor, setVendor] = useState(viewFilters.vendor);
  const [sort, setSort] = useState(viewFilters.sort);
  const [currentPage, setCurrentPage] = useState(viewFilters.page);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const filterChangeHandler = (event) => {
    setFilter(event.target.value);
  };

  const sortChangeHanlder = (event) => {
    setSort(event.target.value);
  };

  const paginate = useCallback((event, value) => {
    setCurrentPage(value);
  }, []);

  const titleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const vendorChangeHandler = (event) => {
    setVendor(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      filter: filter,
      title: title,
      sort: sort,
      vendor: vendor,
      vendorContains: vendorContains,
      last_seen: selectedDate,
    };
    dispatch(requestOffers(params));
    dispatch(setOffersViewFilters(params));
    //eslint-disable-next-line
  }, [currentPage, filter, sort, selectedDate]);

  const searchSubmitHandler = () => {
    const params = {
      page: currentPage,
      filter: filter,
      title: title,
      sort: sort,
      vendor: vendor,
      vendorContains: vendorContains,
      last_seen: selectedDate,
    };
    dispatch(requestOffers(params));
    dispatch(setOffersViewFilters(params));
  };

  return (
    <>
      <div className={classes.main}>
        <div className={classes.pagination}>
          <Pagination
            className={classes.rootpage}
            count={props.pagesCount}
            color="primary"
            page={currentPage}
            onChange={paginate}
          />
        </div>
        {currentPage === 1 && (
          <div className={classes.root} noValidate autoComplete="off">
            <TextField
              label="Title"
              color="secondary"
              variant="outlined"
              value={title}
              onKeyPress={(event) => {
                if (event.charCode === 13) searchSubmitHandler();
              }}
              onChange={titleChangeHandler}
            ></TextField>
            <Divider light />
            <TextField
              label="Vendor"
              color="secondary"
              variant="outlined"
              value={vendor}
              onKeyPress={(event) => {
                if (event.charCode === 13) searchSubmitHandler();
              }}
              onChange={vendorChangeHandler}
            ></TextField>
            <div className={classes.checkBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={vendorContains}
                    onChange={(e) => setVendorContains(e.target.checked)}
                  />
                }
                label="Contains"
              />
            </div>
            <Divider light />
            <TextField
              id="sort"
              select
              label="Sort"
              value={sort}
              color="secondary"
              variant="outlined"
              onChange={sortChangeHanlder}
            >
              {sortMethod.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="filter"
              select
              label="Filter"
              value={filter}
              color="secondary"
              variant="outlined"
              onChange={filterChangeHandler}
            >
              {filterMethod.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Last Seen After"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>

          </div>
        )}
      </div>
    </>
  );
};

export default Filters;
