import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import "../App.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Modal as Modal_swal } from "../utils/helper";

import {
  getStateData,
  getStateLoading,
  getStateErrorr,
  getSellers,
  getRegions,
} from "../selectors/selector";
import {
  getSellersFetch,
  loadScrapper,
  updateUser,
  deleteUser,
  getRegionsFetch,
} from "../actions";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    marginTop: "3%",
    marginLeft: "3.5%",
    width: "93%",
  },
  rootpage: {
    "& > *": {
      marginTop: "10px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%", // increase the width
    margin: "auto", // center horizontally
  },
  paper: {
    backgroundColor: "white",
    padding: "50px 300px 50px 300px ",
    borderRadius: "10px",
    outline: "none",
    width: "80%", // increase the width
    maxHeight: "80%", // increase the height

    position: "relative", // add position relative to the modal paper
    textAlign: "center",
    display: "flex", // add flex display
    flexDirection: "column", // make the children align vertically
    alignItems: "center", // center the children horizontally
  },

  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    padding: "5px",
    borderRadius: "50%",
    cursor: "pointer",
  },
  button: { marginLeft: "5px", marginTop: "20px", width: "100%" },
});

export default function CustomizedTables() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(getStateLoading);
  const summary = useSelector(getStateData);
  const err = useSelector(getStateErrorr);
  const user = useSelector(getSellers);
  const regions = useSelector(getRegions);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [selectedOptionSeller, setSelectedOptionSeller] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState();
  const [selectedId, setSelectedId] = useState();
  const [refreshData, setRefreshData] = useState();

  const handleRegionChange = (event) => {
    setSelectedRegions(event.target.value);
  };
  const handleSellerChange = (event, value, id) => {
    const selectedOption = user.data.find((option) => option.name === value);
    const selectedOptionId = selectedOption?.id;
    setSelectedSeller(selectedOptionId);
  };
  const [rowId, setRowId] = useState(null);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleModalOpen = (event, value, id, row) => {
    toggleModal();
    setSelectedId(id);
    row["name"] = row["seller_name"];
    setSelectedRegions(row.regions);
    setSelectedSeller(row.seller);
    setSelectedOptionSeller(row);
    setSelectedOption(value);
    setSelectedOptionId(id);
    setRowId(id);
    //dispatch(loadScrapper(1));
  };

  const handleSaveAndReload = () => {
    const payload = {
      seller: selectedSeller,
      id: selectedId,
      regions: selectedRegions,
    };
    dispatch(updateUser(payload));
    dispatch(loadScrapper(1));
    handleModalClose();
    setRefreshData(1);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedOption(null);
    setSelectedOptionId(null);
    setRowId(null);
  };

  const handleSave = (event, value, id) => {
    handleSaveAndReload();
  };

  const handleDelete = (event, id) => {
    const payload = {
      id: id,
    };
    Modal_swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this item.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(payload);
        dispatch(loadScrapper(1));
      }
    });
  };

  const deleteItem = (payload) => {
    dispatch(deleteUser(payload));
  };

  useEffect(() => {
    if (!modalOpen) {
      dispatch(loadScrapper(1));
    }
    const params = {
      no_pagination: true,
    };
    setRefreshData(0);
    dispatch(getSellersFetch(params));
    dispatch(getRegionsFetch(params));
  }, [refreshData]);
  const regionMap = {
    1: "SE",
    2: "W",
    3: "SW",
    4: "NE",
    5: "MW",
    6: "CA",
  };

  return loading ? (
    <CircularProgress style={{ marginTop: "15%", marginLeft: "50%" }} />
  ) : (
    <div>
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Username</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Staff</StyledTableCell>
              <StyledTableCell>Superuser</StyledTableCell>
              <StyledTableCell>Seller</StyledTableCell>
              <StyledTableCell>Region</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summary.count &&
              summary.results.map((row) => (
                <TableRow key={row.id}>
                  <StyledTableCell>{row.id}</StyledTableCell>
                  <StyledTableCell>{row.username}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>{row.is_staff.toString()}</StyledTableCell>
                  <StyledTableCell>
                    {row.is_superuser.toString()}
                  </StyledTableCell>
                  <StyledTableCell>{row.seller_name}</StyledTableCell>
                  <StyledTableCell>
                    {row.regions.map((region) => regionMap[region]).join(", ")}
                  </StyledTableCell>

                  <StyledTableCell>
                    <Button
                      onClick={(event) =>
                        handleModalOpen(event, row.username, row.id, row)
                      }
                    >
                      <EditIcon>Edit Seller</EditIcon>
                    </Button>
                    <Button onClick={(event) => handleDelete(event, row.id)}>
                      <DeleteOutlineIcon></DeleteOutlineIcon>
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={modalOpen} onClose={toggleModal} className={classes.modal}>
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={toggleModal}>
            <ClearIcon style={{ color: "lightblue" }} />
          </div>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>

          <h2 id="simple-modal-title">Edit {selectedOption}</h2>
          <Autocomplete
            id={`seller-${rowId}`}
            options={user.data}
            defaultValue={selectedOptionSeller}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) =>
              handleSellerChange(event, value?.name, selectedOptionId)
            }
            renderInput={(params) => (
              <TextField
                value={selectedOptionSeller}
                {...params}
                margin="normal"
                variant="outlined"
                label="Seller"
                style={{ width: "100%" }} // add this line to set width to 100%
              />
            )}
            disabled={loading}
            style={{ width: "100%" }} // add this line to set width to 100%
          />

          <FormControl
            variant="outlined"
            label="Region"
            style={{ width: "100%", margin: "normal", textAlign: "left" }}
          >
            <InputLabel id="region-selector-label">Region</InputLabel>
            <Select
              labelId="region-selector-label"
              id="region-selector"
              multiple
              value={selectedRegions}
              label="Region"
              onChange={handleRegionChange}
              renderValue={(selectedRegions) =>
                selectedRegions
                  .map(
                    (id) =>
                      regions?.data?.results?.find((region) => region.id === id)
                        ?.name
                  )
                  .join(", ")
              }
            >
              {regions?.data?.results?.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  <Checkbox checked={selectedRegions.indexOf(region.id) > -1} />
                  <ListItemText primary={region.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Modal>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          className={classes.rootpage}
          count={summary.total_pages}
          color="primary"
          page={summary.current_page}
          onChange={(event, value) => {
            dispatch(loadScrapper(value));
          }}
        />
      </div>
      <div className="errorlength">
        {err && <div className="error">{err}</div>}
      </div>
    </div>
  );
}
