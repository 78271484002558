import { OFFERSDATA } from "../constants/index";

const offersState = {
  loading: false,
  error: null,
  mappedProduct: null,
  offers: [],
  offer: null,
  createError: null,
  createLoading: false,
  updateError: null,
  updateLoading: false,
  viewFilters: {
    page: 1,
    sort: "default",
    filter: "nofilter",
    title: "",
    vendor: "",
    vendorContains: false,
  },
};

export const OffersReducer = (state = offersState, action) => {
  switch (action.type) {
    case OFFERSDATA.REQUEST_OFFERS:
    case OFFERSDATA.REQUEST_OFFER:
    case OFFERSDATA.SEARCH_OFFERS:
    case OFFERSDATA.REQUEST_MAPPED_PRODUCT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case OFFERSDATA.REQUEST_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.payload,
        loading: false,
        error: null,
      };
    case OFFERSDATA.REQUEST_OFFER_SUCCESS:
      return {
        ...state,
        offer: action.payload,
        loading: false,
        error: null,
      };
    case OFFERSDATA.REQUEST_OFFERS_FAILED:
    case OFFERSDATA.REQUEST_OFFER_FAILED:
    case OFFERSDATA.REQUEST_MAPPED_PRODUCT_FAILED:
      return {
        ...state,
        offers: [],
        offer: null,
        loading: false,
        error: action.error,
      };
    case OFFERSDATA.REQUEST_MAPPED_PRODUCT_SUCCESS:
      return {
        ...state,
        mappedProduct: action.payload,
        loading: false,
        error: null,
      };
    case OFFERSDATA.CREATE_PRODUCT:
      return {
        ...state,
        createLoading: true,
      };
    case OFFERSDATA.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        mappedProduct: action.payload,
      };
    case OFFERSDATA.CREATE_PRODUCT_FAILED:
      return {
        ...state,
        createLoading: false,
        createError: action.error,
      };
    case OFFERSDATA.RESET_OFFER:
      return {
        ...state,
        offer: null,
        createError: null,
        createLoading: false,
      };
    case OFFERSDATA.RESET_OFFERS:
      return {
        ...state,
        offers: [],
      };
    case OFFERSDATA.RESET_MAPPED_PRODUCT:
      return {
        ...state,
        mappedProduct: null,
      };
    case OFFERSDATA.SET_OFFERS_VIEW_FILTERS:
      return {
        ...state,
        viewFilters: action.payload,
      };
    case OFFERSDATA.UPDATE_OFFER:
      return {
        ...state,
        updateLoading: true,
      };
    case OFFERSDATA.UPDATE_OFFER_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: null,
        offer: action.payload,
      };
    case OFFERSDATA.UPDATE_OFFER_FAILED:
      return {
        ...state,
        updateLoading: false,
        updateError: action.error,
      };
    default:
      return state;
  }
};
