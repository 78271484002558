import { SCRAPPERDATA } from "../constants/index";
const scrapperState = {
  data: [],
  count: [],
  error: "",
  loading: true,
  success: false,
  stat: 0,
  date: new Date().toISOString().substring(0, 10),
  pageData: {},
  page: 1,
};
export const ScrapperReducer = (state = scrapperState, action) => {
  switch (action.type) {
    case SCRAPPERDATA.LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        date: action.date,
        stat: action.stat,
        page: action.page,
      };
    case SCRAPPERDATA.LOAD_DATA:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
        page: action.payload.page,
        loading: false,
        error: null,
        success: false,
        date: action.payload.date,
        stat: action.payload._state,
      };
    case SCRAPPERDATA.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    case SCRAPPERDATA.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      };
    case SCRAPPERDATA.CLEAR:
      return {
        ...state,
        pageData: {},
      };
    case SCRAPPERDATA.ADD:
      return {
        ...state,
        pageData: (pageDate[action.payload.total_pages] = action.payload.res),
      };
    default:
      return state;
  }
};
